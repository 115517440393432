




























































import { Component, Vue } from "vue-property-decorator";
import RequestHandler from "@/assets/ts/requestHandler.ts";

const RH = new RequestHandler();

@Component
export default class ForgotUserPass extends Vue {
  private username = "";
  private newPassword = "";
  private guid = "";
  private showPass = false;

  private rules = {
    required: (value: string | number) => !!value || "This field is required.",
    password: (value: string) => {
      const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[~`!@#$%^&*()_+={[}\]|\\:;"'<,>.?/-])[A-Za-z\d~`!@#$%^&*()_+={[}\]|\\:;"'<,>.?/-]{8,}$/;
      return (
        pattern.test(value) ||
        "Must contain one lowercase letter, one uppercase letter, one number, one special character, and be at least eight characters in total"
      );
    }
  };

  get passwordResetForm(): Vue & { validate: () => boolean } {
    return this.$refs.passwordResetForm as Vue & { validate: () => boolean };
  }

  public async resetPassword() {
    if (this.passwordResetForm.validate()) {
      try {
        await RH.resetForgotPassword(
          this.username,
          this.newPassword,
          this.newPassword,
          this.guid
        );
        this.$root.$emit("snackbar-message", "Password reset successfully.");
        this.$router.replace({ name: "Login" });
      } catch (e) {
        this.$root.$emit(
          "snackbar-message",
          "Failed to reset password. Please try again."
        );
      }
    }
  }

  created() {
    this.guid = this.$route.params.guid;
  }
}
